<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  @media (max-width: 1024px) {
    .sm-spacer {
      margin-top: .5rem;
    }
  }
  .save-btn.btn-success {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #34c38f;
    }

    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import {Game, OmegaAction, StreamEvent, TriggerAttributes, TriggerCondition} from "@/enums";

export default {
  props: ['server_id', 'options'],
  components: {
    VclCode,
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    Multiselect
  },
  computed: {
    ...mapGetters([
      'getServer'
    ]),
  },
  methods: {
    // Source: https://gist.github.com/jed/982883
    uuid(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,this.uuid)},
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getConfiguration: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/triggers`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.configuration = data.configuration;
          this.limit = data.limit;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    /* Support features per game */
    supportsKillfeed() {
      // Supports modding or has native method of kill transmission
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsWhitelist() {
      // Supports either native whitelist or emulated whitelist by kicking
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsReservedSlots() {
      // Either native or emulated reserved slot support by kicking
      let games = [];
      return games.includes(this.options.game);
    },
    supportsIPFeatures() {
      // Games that allow the retrieval of plain text player IP
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsSteam() {
      // Steam auth based games
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsGameIntegration() {
      // Steam auth based games
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsGameAttributes() {
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    /* ************************** */
    /* Special features for actions */

    actionToString(action) {
      switch(action) {
        case OmegaAction.HELP: return this.$t('omega.actions.help');
        case OmegaAction.RAW: return this.$t('omega.actions.raw');
        case OmegaAction.MESSAGE_PRIVATE: return this.$t('omega.actions.message_private');
        case OmegaAction.MESSAGE_PUBLIC: return this.$t('omega.actions.message_public');
        case OmegaAction.SERVER_LOCK: return this.$t('omega.actions.server_lock');
        case OmegaAction.SERVER_UNLOCK: return this.$t('omega.actions.server_unlock');
        case OmegaAction.SERVER_RESTART: return this.$t('omega.actions.server_restart');
        case OmegaAction.SERVER_KICK: return this.$t('omega.actions.server_kick');
        case OmegaAction.SERVER_WIPE: return this.$t('omega.actions.server_wipe');
        case OmegaAction.WHITELIST_PAUSE: return this.$t('omega.actions.whitelist_pause');
        case OmegaAction.WHITELIST_RESUME: return this.$t('omega.actions.whitelist_resume');
        case OmegaAction.GAME_KILL: return this.$t('omega.actions.game_kill');
      }
      return `ERR (${action})`;
    },
    eventToString(event) {
      switch(event) {
        case StreamEvent.CHAT: return this.$t('omega.events.chat');
        case StreamEvent.JOIN: return this.$t('omega.events.join');
        case StreamEvent.LEAVE: return this.$t('omega.events.leave');
        case StreamEvent.LOADED: return this.$t('omega.events.loaded');
        case StreamEvent.TICK: return this.$t('omega.events.tick');
        case StreamEvent.UPDATE: return this.$t('omega.events.update');
        case StreamEvent.KICK: return this.$t('omega.events.kick');
        case StreamEvent.BAN: return this.$t('omega.events.ban');
        case StreamEvent.KILL: return this.$t('omega.events.kill');
      }
      return `ERR (${event})`;
    },
    attributeToString(attribute) {
      switch(attribute) {
        case TriggerAttributes.MESSAGE: return this.$t('omega.attributes.message');
        case TriggerAttributes.CFTOOLS_ID: return 'CFTools ID';
        case TriggerAttributes.PLAYER_NAME: return this.$t('omega.attributes.player_name');
        case TriggerAttributes.SESSION_TIME: return this.$t('omega.attributes.session_time');
        case TriggerAttributes.IS_ADMIN: return this.$t('omega.attributes.is_admin');
        case TriggerAttributes.HOUR: return this.$t('omega.attributes.hour');
        case TriggerAttributes.MINUTE: return this.$t('omega.attributes.minute');
        case TriggerAttributes.POSITION: return this.$t('omega.attributes.position');
        case TriggerAttributes.SERVER_QUEUE: return this.$t('omega.attributes.server_queue');
        case TriggerAttributes.SERVER_PLAYERS: return this.$t('omega.attributes.server_players');
        case TriggerAttributes.SERVER_LOCK_STATE: return this.$t('omega.attributes.server_lock_state');
        case TriggerAttributes.SERVER_GAMETIME: return this.$t('omega.attributes.server_gametime');
      }
      return `ERR (${attribute})`;
    },
    conditionToString(condition) {
      switch(condition) {
        case TriggerCondition.MATCH: return this.$t('omega.conditions.match');
        case TriggerCondition.LESS_THEN: return this.$t('omega.conditions.less_then');
        case TriggerCondition.GREATER_THEN: return this.$t('omega.conditions.greater_then');
        case TriggerCondition.GEO_POSITION: return this.$t('omega.conditions.geo_position');
        case TriggerCondition.TRUE: return this.$t('omega.conditions.true');
        case TriggerCondition.FALSE: return this.$t('omega.conditions.false');
      }
      return `ERR (${condition})`;
    },
    actionHasParameter(action) {
      let unParameterizedActions = [
        OmegaAction.HELP,
        OmegaAction.GAME_KILL,
        OmegaAction.WHITELIST_PAUSE,
        OmegaAction.WHITELIST_RESUME,
        OmegaAction.SERVER_LOCK,
        OmegaAction.SERVER_UNLOCK,
      ];
      return !(unParameterizedActions.includes(action));
    },
    conditionHasValue(condition) {
      if(condition === null) return false;
      let unParameterizedConditions = [
        TriggerCondition.TRUE, TriggerCondition.FALSE
      ];
      return !(unParameterizedConditions.includes(condition));
    },
    conditionValueType(condition) {
      let integerConditions = []; // [TriggerCondition.LESS_THEN, TriggerCondition.GREATER_THEN];
      if(integerConditions.includes(condition)) return 'number';
      else if(condition === TriggerCondition.GEO_POSITION) return 'geo';
      else return 'text';
    },
    /* ************************** */
    async saveConfiguration() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let triggers = [];
        /* Integrity check */
        this.configuration.triggers.forEach((trigger) => {
          if(trigger.event === null) return;
          else if(trigger.action === null) return;
          else triggers.push(trigger)
        });
        this.configuration.triggers = triggers;
        /* *************** */
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          configuration: this.configuration
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/triggers`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.configuration.save.success.title'),
            text: this.$t('server.view.configuration.save.success.text'),
          });
          this.configurationChanged = null;
          await this.getConfiguration();
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    },
    /* Processing */
    async addTriggerElement() {
      this.configuration.triggers.push({
        event: null,
        action: null,
        parameter: null,
        conditions: [],
        uuid: this.uuid()
      });
    },
    async addNewCondition(trigger) {
      trigger.conditions.push({
        condition: null,
        attribute: null,
        value: null
      });
    },
    async removeTriggerCondition(trigger, targetIndex) {
      let conditions = [];
      trigger.conditions.forEach((condition, index) => {
        if(index !== targetIndex) conditions.push(condition);
      });
      trigger.conditions = [];
      trigger.conditions = conditions;
    },
    async removeElement(uuid) {
      let elements = [];
      this.configuration.triggers.forEach((trigger) => {
        if(trigger.uuid !== uuid) elements.push(trigger);
      });
      this.configuration.triggers = [];
      this.configuration.triggers = elements;
    },
    async prefillCondition(condition) {
      let valueType = this.conditionValueType(condition.condition);
      switch(valueType) {
        case 'text': {
          condition.value = '';
          break;
        }
        case 'number': {
          condition.value = 0;
          break;
        }
        case 'geo': {
          condition.value = [0, 0, 0];
          break;
        }
        default: {
          condition.value = '';
          break;
        }
      }
    },
    /* ***** */
	  scrollHandler() {
		  if(!this.$refs.floatingContainer) return;

		  if(window.pageYOffset > 70) {
			  if(!this.$refs.floatingContainer.classList.contains('c-floating-side')) {
				  this.$refs.floatingContainer.classList.add('c-floating-side');
			  }
		  } else {
			  this.$refs.floatingContainer.classList.remove('c-floating-side');
		  }
	  }
  },
  created() {
    this.server = this.getServer(this.server_id);
    if(this.supportsGameIntegration()) {
      //this.availableActions.push(OmegaAction.GAME_KILL);
      //this.availableAttributes.push(TriggerAttributes.POSITION);
      //this.availableConditions.push(TriggerCondition.GEO_POSITION);
    }
    if(this.supportsKillfeed()) {
      this.availableEvents.push(StreamEvent.KILL);
      //if(!this.availableAttributes.includes(TriggerAttributes.POSITION)) {
      // this.availableAttributes.push(TriggerAttributes.POSITION);
      //}
      //if(!this.availableConditions.includes(TriggerCondition.GEO_POSITION)) {
      //this.availableConditions.push(TriggerCondition.GEO_POSITION);
      //}
    }
    if(this.supportsGameAttributes()) {
      this.availableAttributes.push(TriggerAttributes.SERVER_GAMETIME);
    }
  },
  mounted() {
    this.getConfiguration();

	  window.addEventListener('scroll', this.scrollHandler);
  },
	destroyed() {
		window.removeEventListener('scroll', this.scrollHandler);
	},
  watch: {
    configuration: {
      deep: true,
      handler() {
        if(!this.ready) return;
        if(this.configurationChanged === null) {
          this.configurationChanged = false;
        } else this.configurationChanged = true;
      }
    }
  },
  data() {
    return {
      Game:Game,
      OmegaAction:OmegaAction,
      TriggerCondition:TriggerCondition,
      TriggerAttributes:TriggerAttributes,
      StreamEvent:StreamEvent,
      ready: false,
      error: false,
      inProgress: false,
      server: null,
      configurationChanged: null,
      configuration: {

      },
      limit: null,
      availableEvents: [
        StreamEvent.CHAT,
        StreamEvent.JOIN,
        StreamEvent.LEAVE,
        StreamEvent.LOADED,
        StreamEvent.TICK,
        StreamEvent.UPDATE,
        StreamEvent.KICK,
        StreamEvent.BAN
      ],
      availableActions: [
        OmegaAction.RAW,
        OmegaAction.MESSAGE_PRIVATE,
        OmegaAction.MESSAGE_PUBLIC,
        OmegaAction.SERVER_LOCK,
        OmegaAction.SERVER_UNLOCK,
        OmegaAction.SERVER_RESTART,
        OmegaAction.SERVER_KICK,
        OmegaAction.SERVER_WIPE,
        OmegaAction.WHITELIST_PAUSE,
        OmegaAction.WHITELIST_RESUME
      ],
      availableAttributes: [
        TriggerAttributes.MESSAGE,
        TriggerAttributes.CFTOOLS_ID,
        TriggerAttributes.PLAYER_NAME,
        TriggerAttributes.SESSION_TIME,
        TriggerAttributes.IS_ADMIN,
        TriggerAttributes.HOUR,
        TriggerAttributes.MINUTE,
        TriggerAttributes.SERVER_LOCK_STATE,
        TriggerAttributes.SERVER_QUEUE,
        TriggerAttributes.SERVER_PLAYERS,
      ],
      availableConditions: [
        TriggerCondition.MATCH,
        TriggerCondition.LESS_THEN,
        TriggerCondition.GREATER_THEN,
        TriggerCondition.TRUE,
        TriggerCondition.FALSE
      ],
      // Special configs
      special: {

      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-sm-12 d-lg-none">
          <button class="btn btn-block hard-border save-btn"
                  v-on:click="saveConfiguration()"
                  :disabled="inProgress"
                  :class="{
                    'disabled': inProgress,
                    'btn-success': configurationChanged === true,
                    'btn-primary': configurationChanged === false
                  }"
          >
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.view.configuration.save.button') }}
            </template>
          </button>
          <div class="text-center small text-muted" v-if="configurationChanged === true">
            {{ $t('server.view.configuration.save.info') }}
          </div>
        </div>
        <div class="col-sm-12 mt-2 d-lg-none">
          <template v-if="!limit || configuration.triggers.length < limit">
            <button class="btn btn-info btn-block hard-border" v-on:click="addTriggerElement()">
              <i class="far fa-plus" />
              {{ $t('server.view.triggers.button') }}
            </button>
          </template>
          <template v-if="limit">
            <div class="card text-center mb-0">
              <div class="card-body" v-if="configuration.triggers.length >= limit">
                <h5 class="text-danger">
                  <i class="fad fa-times-hexagon text-danger"/>
                  {{$t('server.view.triggers.limits.reached')}}
                </h5>
                <span class="h5 text-danger">{{configuration.triggers.length}} / {{limit}}</span>
              </div>
              <div class="card-body" v-else>
                <h5>
                  {{$t('server.view.triggers.limits.info')}}
                </h5>
                <span class="h5">{{configuration.triggers.length}} / {{limit}}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="col-sm-12 mt-2 d-lg-none">
          <div class="card hard-border ">
            <div class="card-body">
              <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
              {{$t('server.view.configuration.placeholders.message')}}
              <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
                <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-sm-12 ">
          <div>
            <div role="tablist">
              <b-card no-body class="settings-collapse hard-border" v-for="trigger in configuration.triggers" :key="trigger.uuid">
                <a v-b-toggle="trigger.uuid" class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      <template v-if="trigger.event !== null && trigger.action !== null">
                        {{ eventToString(trigger.event) }}

                        <small class="ml-2 mr-1 mt-1">
                          <i class="far fa-code-merge font-size-15" />
                          {{ actionToString(trigger.action) }}
                        </small>
                      </template>
                      <template v-else>
                        <div class="badge badge-warning text-black">
                          {{ $t('server.view.triggers.unconfigured') }}
                        </div>
                      </template>
                      <small class="text-muted font-size-12 mt-2 ml-1 hide-sm">
                        {{ trigger.uuid }}
                      </small>
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('server.view.configuration.expand') }}
                      </div>
                    </div>
                    <div class="d-inline-flex float-right mr-2">
                      <div class="btn btn-sm btn-danger" v-on:click="removeElement(trigger.uuid)">
                        <i class="fal fa-trash-alt" />
                        {{ $t('server.view.configuration.remove') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse :id="trigger.uuid" accordion="triggers" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-lg-6 col-sm-12">
                          <h4>{{ $t('server.view.triggers.event.label') }}</h4>
                          <hr>
                          <div class="row">
                            <div class="col">
                              <multiselect
                                  v-model="trigger.event"
                                  :options="availableEvents"
                                  :multiple="false"
                                  :close-on-select="true"
                                  :clear-on-select="false"
                                  :preserve-search="true"
                                  :placeholder="$t('server.view.triggers.event.placeholder')"
                                  :preselect-first="false"
                                  :taggable="false"
                              >
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                                  <div>
                                    <span class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ eventToString(props.option) }}
                                      </span>
                                    </span>
                                  </div>
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="props"
                                >
                                  <div>
                                    <div class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ eventToString(props.option) }}
                                      </span>
                                    </div>
                                  </div>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 sm-spacer">
                          <h4>{{ $t('server.view.triggers.action.label') }}</h4>
                          <hr>
                          <div class="row">
                            <div class="col-lg-12 col-sm-12">
                              <multiselect
                                  v-model="trigger.action"
                                  :options="availableActions"
                                  :multiple="false"
                                  :close-on-select="true"
                                  :clear-on-select="false"
                                  :preserve-search="true"
                                  :placeholder="$t('server.view.triggers.action.placeholder')"
                                  :preselect-first="false"
                                  :taggable="false"
                              >
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                                  <div>
                                    <span class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ actionToString(props.option) }}
                                      </span>
                                    </span>
                                  </div>
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="props"
                                >
                                  <div>
                                    <div class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ actionToString(props.option) }}
                                      </span>
                                    </div>
                                  </div>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="trigger.action && actionHasParameter(trigger.action)">
                        <div class="row mt-3">
                          <div class="col-lg-12 col-sm-12">
                            <h4>{{ $t('server.view.triggers.parameter.label') }}</h4>
                            <hr>
                            <div class="row">
                              <div class="col">
                                <b-input v-model="trigger.parameter" size="lg" type="text" class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div class="row mt-3">
                        <div class="col-lg-12 col-sm-12">
                          <div>
                            <h4 class="d-inline">
                              {{ $t('server.view.triggers.conditions.label') }}
                              <button class="btn btn-primary btn-sm d-inline" v-on:click="addNewCondition(trigger)">
                                <i class="far fa-plus" />
                                {{ $t('server.view.triggers.conditions.add') }}
                              </button>
                            </h4>
                          </div>
                          <hr>
                          <div class="row">
                            <template v-if="trigger.conditions.length === 0">
                              <div class="col">
                                <h5 class="mb-0 text-uppercase text-muted">
                                  {{ $t('server.view.triggers.conditions.always') }}
                                </h5>
                              </div>
                            </template>
                            <div class="col-lg-12 col-sm-12 mt-2" v-for="(condition, index) in trigger.conditions" :key="index">
                              <div class="row">
                                <div class="col-lg-3 col-sm-12">
                                  <h5>{{ $t('server.view.triggers.conditions.attribute.label') }}</h5>
                                  <hr>
                                  <multiselect
                                      v-model="condition.attribute"
                                      :options="availableAttributes"
                                      :multiple="false"
                                      :close-on-select="true"
                                      :clear-on-select="false"
                                      :preserve-search="true"
                                      :placeholder="$t('server.view.triggers.conditions.attribute.placeholder')"
                                      :preselect-first="false"
                                      :taggable="false"
                                  >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="props"
                                    >
                                      <div>
                                    <span class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ attributeToString(props.option) }}
                                      </span>
                                    </span>
                                      </div>
                                    </template>
                                    <template
                                        slot="option"
                                        slot-scope="props"
                                    >
                                      <div>
                                        <div class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ attributeToString(props.option) }}
                                      </span>
                                        </div>
                                      </div>
                                    </template>
                                  </multiselect>
                                </div>
                                <div class="col-lg-3 col-sm-12 sm-spacer">
                                  <h5>{{ $t('server.view.triggers.conditions.condition.label') }}</h5>
                                  <hr>
                                  <multiselect
                                      v-model="condition.condition"
                                      :options="availableConditions"
                                      :multiple="false"
                                      :close-on-select="true"
                                      :clear-on-select="false"
                                      :preserve-search="true"
                                      :placeholder="$t('server.view.triggers.conditions.condition.placeholder')"
                                      :preselect-first="false"
                                      :taggable="false"
                                      @input="prefillCondition(condition)"
                                  >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="props"
                                    >
                                      <div>
                                    <span class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ conditionToString(props.option) }}
                                      </span>
                                    </span>
                                      </div>
                                    </template>
                                    <template
                                        slot="option"
                                        slot-scope="props"
                                    >
                                      <div>
                                        <div class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ conditionToString(props.option) }}
                                      </span>
                                        </div>
                                      </div>
                                    </template>
                                  </multiselect>
                                </div>
                                <div class="col-lg-6 col-sm-12 sm-spacer" v-if="conditionHasValue(condition.condition)">
                                  <h5>{{ $t('server.view.triggers.conditions.value.label') }}</h5>
                                  <hr>
                                  <template v-if="conditionValueType(condition.condition) === 'text'">
                                    <b-input v-model="condition.value" size="lg" type="text" class="form-control" />
                                  </template>
                                  <template v-else-if="conditionValueType(condition.condition) === 'number'">
                                    <b-input v-model="condition.value" size="lg" type="number" class="form-control" />
                                  </template>
                                  <template v-if="conditionValueType(condition.condition) === 'geo'">
                                    <div class="row">
                                      <div class="col">
                                        <b-input v-model="condition.value[0]" size="lg" placeholder="X" type="number" class="form-control" />
                                      </div>
                                      <div class="col">
                                        <b-input v-model="condition.value[1]" size="lg" placeholder="Y" type="number" class="form-control" />
                                      </div>
                                      <div class="col">
                                        <b-input v-model="condition.value[2]" size="lg" placeholder="RADIUS" type="number" class="form-control" />
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </div>
                              <button class="btn btn-sm btn-outline-danger mt-1" v-on:click="removeTriggerCondition(trigger, index)">
                                <i class="fal fa-trash-alt" />
                                {{ $t('server.view.triggers.conditions.remove') }}
                              </button>
                              <hr>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <!---------------------------------------->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-12 hide-sm">
	        <div class="" ref="floatingContainer">
	          <button class="btn btn-block hard-border save-btn"
	                  v-on:click="saveConfiguration()"
	                  :disabled="inProgress"
	                  :class="{
	                    'disabled': inProgress,
	                    'btn-success': configurationChanged === true,
	                    'btn-primary': configurationChanged === false
	                  }"
	          >
	            <half-circle-spinner
	                v-if="inProgress"
	                :animation-duration="1200"
	                :size="16"
	                class="align-middle d-inline-block"
	            />
	            <template v-if="!inProgress">
	              {{ $t('server.view.configuration.save.button') }}
	            </template>
	          </button>
	          <div class="text-center small text-muted" v-if="configurationChanged === true">
	            {{ $t('server.view.configuration.save.info') }}
	          </div>

	          <template v-if="!limit || configuration.triggers.length < limit">
	            <button class="btn btn-info btn-block hard-border mt-2 mb-2" v-on:click="addTriggerElement()">
	              <i class="far fa-plus" />
	              {{ $t('server.view.triggers.button') }}
	            </button>
	          </template>
	          <template v-if="limit">
	            <div class="card text-center mb-0">
	              <div class="card-body" v-if="configuration.triggers.length >= limit">
	                <h5 class="text-danger">
	                  <i class="fad fa-times-hexagon text-danger"/>
	                  {{$t('server.view.triggers.limits.reached')}}
	                </h5>
	                <span class="h5 text-danger">{{configuration.triggers.length}} / {{limit}}</span>
	              </div>
	              <div class="card-body" v-else>
	                <h5>
	                  {{$t('server.view.triggers.limits.info')}}
	                </h5>
	                <span class="h5">{{configuration.triggers.length}} / {{limit}}</span>
	              </div>
	            </div>
	          </template>

	          <div class="row mt-2">
	            <div class="col-lg-12">
	              <div class="card">
	                <div class="card-body">
	                  <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
	                  {{$t('server.view.configuration.placeholders.message')}}
	                  <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
	                    <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
	                  </a>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
